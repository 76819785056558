import { AxiosRequestConfig } from "axios";
import qs from "qs";
interface Config {
  [propName: string]: any;
  development: { baseURL: string };
  production: { baseURL: string };
  base: AxiosRequestConfig;
}

const config: Config = {
  // http://120.24.78.42:8920/yigekeke
  development: {
    baseURL: "http://120.24.78.42:8920" + "/yigekeke", //"http://localhost:8920/yigekeke", //(window as any).DEV,
  },
  production: {
    baseURL: "http://120.24.78.42:8920" + "/yigekeke", //"http://localhost:8920/yigekeke",  window.location.origin// (window as any).PRO,
  },
  base: {
    timeout: 1000 * 300,
    withCredentials: false,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    transformRequest: [
      (data: object, headers: any) => {
        if (headers["Content-Type"] === "application/json") {
          return JSON.stringify(data);
        } else if (headers["Content-Type"] === "multipart/form-data") {
          return data;
        }
        return qs.stringify(data);
      },
    ],
  },
};

export default config;
