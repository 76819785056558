import Vue, { VueConstructor } from "vue";
import Cookies from "js-cookie";
import Plugin from "@/plugins/plugin";
import { ClassFi, Column, CommonObj, GeneralEnum } from "@/types";

class Misc extends Plugin {
  vm: Vue;
  name = "misc";

  constructor(Vue: VueConstructor) {
    super(Vue);
    this.vm = Vue.prototype;
  }

  deepClone<T extends CommonObj>(obj: T): T {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    let result: any = typeof obj.splice === "function" ? [] : {};
    let key;
    if (obj && typeof obj === "object") {
      for (key in obj) {
        if (obj[key] && typeof obj[key] === "object") {
          result[key] = this.deepClone(obj[key]);
        } else {
          result[key] = obj[key];
        }
      }
      return result;
    }
    return obj;
  }

  // 时间戳格式化
  formatTimeStamp(
    stamp: any,
    full: boolean = false,
    type: boolean = false,
    onlyTime: boolean = false,
    forSpan?: any
  ) {
    if (typeof stamp !== "number") {
      return stamp;
    }
    if (stamp.toString().length === 10) {
      stamp *= 1000;
    }
    let time = new Date(stamp);
    let y = time.getFullYear();
    let m = (time.getMonth() + 1).toString();
    let d = time.getDate().toString();
    let h = time.getHours().toString();
    let min = time.getMinutes().toString();
    let s = time.getSeconds().toString();
    const fill = (val: string) => (val.length === 2 ? val : `0${val}`);
    let formatted;
    let span = forSpan || "/";
    if (full) {
      if (fill(h) == "00" && fill(min) == "00" && fill(s) == "00") {
        formatted = `${y}${span}${fill(m)}${span}${fill(d)}`;
      } else {
        formatted = `${y}${span}${fill(m)}${span}${fill(d)} ${fill(h)}:${fill(
          min
        )}:${fill(s)}`;
      }
    } else {
      formatted = type
        ? `${y}${span}${fill(m)}${span}${fill(d)}`
        : `${y}${fill(m)}${fill(d)}`;
    }
    if (onlyTime) {
      formatted = `${fill(h)}:${fill(min)}`;
    }
    return formatted;
  }

  // 列表格式化
  renderColumn(row: any, column: Column<any>) {
    let result = row[column.prop];
    // 如果是小数点位数大于4的小数 那就保留四位小数
    if (this.isDecimal(result)) {
      result = this.formatDecimal(result, 4);
    }

    if (column.render) {
      result = column.render(result, row);
    }

    return "" === result || null === result || undefined === result
      ? "--"
      : result;
  }

  formatDecimal(value: string | number, maxDecimalLength = 4) {
    if (!this.isDecimal(value)) {
      return value;
    }

    let decimalLength = value.toString().split(".")[1].length;
    if (decimalLength > maxDecimalLength) {
      value = (+value).toFixed(maxDecimalLength);
    }
    return value;
  }

  isDecimal(val: string | number) {
    if (!this.isNumberLike(val)) {
      return false;
    }
    if (typeof val === "string") {
      val = +val;
    }
    return val != parseInt(val.toString(), 10);
  }

  isNumberLike(val: string | number) {
    // 是数字但是不是NaN   是字符串是数字
    return (
      (typeof val === "number" && !isNaN(val)) ||
      (typeof val === "string" && !isNaN(+val))
    );
  }

  renderLabel(
    val: any,
    list: GeneralEnum[],
    textOnly = false,
    colorText = false,
    backAndColor = false
  ) {
    if (!val) return "--";
    if (!list) {
      return val;
    }
    let matched = list.find((item) => item.value == val);
    let renderSpan = "";

    renderSpan = matched
      ? textOnly
        ? matched.label
        : matched.color
        ? `<span style='color:#fff;background-color:${matched.color};padding: 2px 8px;border-radius: 2px'>${matched.label}</span>`
        : matched.label
      : val;
    if (colorText)
      // 仅仅文字颜色
      renderSpan = matched
        ? textOnly
          ? matched.label
          : matched.color
          ? `<span style='color:${matched.color};'>${matched.label}</span>`
          : matched.label
        : val;
    if (backAndColor)
      renderSpan = matched
        ? textOnly
          ? matched.label
          : matched.color
          ? `<span style='color:${matched.color};background-color:${matched.color};padding: 2px 8px;border-radius: 4px;font-weight: 500;'>${matched.label}</span>`
          : matched.label
        : val;
    return renderSpan;
  }

  isJson(obj: any) {
    let class2type = {};
    let toString = class2type.toString;
    let hasOwn = class2type.hasOwnProperty;
    let fnToString = hasOwn.toString;
    let ObjectFunctionString = fnToString.call(Object);

    if (!obj || toString.call(obj) !== "[object Object]") {
      return false;
    }

    let proto = Object.getPrototypeOf(obj);

    if (!proto) {
      return true;
    }

    let ctor = hasOwn.call(proto, "constructor") && proto.constructor;
    return (
      typeof ctor === "function" &&
      fnToString.call(ctor) === ObjectFunctionString
    );
  }

  getClassFiList() {
    let localhost_list = localStorage.getItem("classFiList");
    let list = [];
    if (localhost_list) {
      list = JSON.parse(localhost_list).map((item: ClassFi) => {
        return {
          value: item.id,
          label: item.classFiName,
          color: item.classFiBackColor,
        };
      });
    } else {
      list = [];
    }
    return list;
  }

  getUserInfo() {
    let get_userInfo = localStorage.getItem("userInfo");
    let set_userInfo = null;
    if (get_userInfo) {
      set_userInfo = JSON.parse(get_userInfo);
    } else {
      set_userInfo = null;
    }
    return set_userInfo;
  }
  getAllUser() {
    let get_allUserList = localStorage.getItem("allUserList");
    let set_allUserList = [];
    if (get_allUserList) {
      set_allUserList = JSON.parse(get_allUserList).map((item: any) => {
        return {
          ...item,
          value: item.userId,
          label: item.userName,
        };
      });
    } else {
      set_allUserList = [];
    }
    return set_allUserList;
  }
}
export default Misc;
