import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import helper from "@/plugins/helper";
import interaction from "@/plugins/interaction";

import VTooltip from "v-tooltip";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/assets/styles/index.scss";

import api from "@/plugins/api";

// 通用组件
import BaseCrud from "@/components/Base/BaseCrud.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(api);
Vue.use(helper);
Vue.use(interaction);
Vue.use(VTooltip, {
  defaultBoundariesElement: "body",
});

// 通用组件
Vue.component("BaseCrud", BaseCrud);
Vue.component("BaseDialog", BaseDialog);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
